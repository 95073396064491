import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";
import Glide from "@glidejs/glide";
import { useEffect, useMemo, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Vehicle } from "../../graphql/generated/graphql";
import HeroGalery from "./HeroGalery";
import { formatPrice } from "../../utils/formatters";

type CollectionVehicleType = Array<Vehicle>;

export function HeroSlider({
  loading,
  featuredVehicles = [],
}: {
  loading?: boolean;
  featuredVehicles: CollectionVehicleType;
}) {
  const [glide, setGlide] = useState(null);
  useEffect(() => {
    if (!glide) {
      setGlide(
        new Glide(".glide", {
          autoplay: 4500,
          rewind: true,
        })
      );
    }
  }, [glide]);

  useEffect(() => {
    if (featuredVehicles.length && glide) {
      glide.mount();
      glide.update({
        startAt: Math.floor(Math.random() * featuredVehicles.length),
      });
    }
  }, [featuredVehicles, glide]);

  const handleNext = () => {
    glide.pause();
    glide.go(">");
  };

  const handlePrev = () => {
    glide.pause();
    glide.go("<");
  };

  const itemsArray = useMemo(() => {
    if (!loading && featuredVehicles?.length) {
      return (
        featuredVehicles
          .map(
            ({
              _id,
              slug,
              thumbs,
              pictures,
              price,
              currency,
              brand,
              model,
              year,
              listingTier,
            }) => ({
              _id,
              title: `${brand} ${model} ${year}`,
              slug,
              thumbs,
              pictures,
              price: formatPrice(price, currency),
              listingTier,
              // sort: Math.random(),
            })
          )
          // .sort((a, b) => a.sort - b.sort)
          .map(({ ...vehicleData }) => vehicleData)
      );
    }

    return [];
  }, [loading, featuredVehicles]);

  return (
    <div className="relative">
      <div className="glide">
        <div data-glide-el="track" className="glide__track">
          <ul className="glide__slides">
            {itemsArray.map((item, idx) => {
              const vehicleItem = item;
              const vehicleThumbs = vehicleItem.thumbs;

              return (
                <li key={`slide-${item._id}`} className="glide__slide">
                  <HeroGalery
                    title={vehicleItem.title}
                    price={vehicleItem.price}
                    picture={vehicleItem.pictures[0]}
                    listingTier={vehicleItem.listingTier}
                    thumbs={vehicleThumbs}
                    slug={item.slug}
                    index={idx}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="absolute transition-all duration-700 z-10 bottom-2 right-2 lg:right-4 ">
        <button
          onClick={handlePrev}
          className="bg-white text-gray-400 hover:text-gray-600 p-1 transition-all rounded-l-md backdrop-blur-sm opacity-85 md:opacity-80"
        >
          <ChevronLeftIcon className="h-6 w-6 " aria-hidden="true" />
        </button>

        <button
          onClick={handleNext}
          className="bg-white  text-gray-400 hover:text-gray-600 p-1 transition-all rounded-r-md backdrop-blur-sm  opacity-85 md:opacity-80"
        >
          <ChevronRightIcon className="h-6 w-6 " aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
