import Image from "next/image";
import {
  classNames,
  replaceImageExtensionToWebP,
  replaceImageTransformation,
} from "../../utils/formatters";
import Link from "next/link";

interface HeroGaleryInterface {
  picture: string;
  thumbs: string[];
  title: string;
  price: string;
  slug: string;
  listingTier: string;
  index: number;
}

export default function HeroGalery({
  picture,
  thumbs,
  title,
  price,
  slug,
  listingTier,
  index,
}: HeroGaleryInterface) {
  return (
    <Link href={`/autosusados/${slug}`}>
      <div className="w-full md:min-h-[400px] grid grid-cols-1 gap-y-1 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-1 grid-rows-3 sm:grid-rows-1 lg:px-2">
        {/* first div */}
        <div className="relative col-span-2 row-span-2">
          <div className="absolute top-2 left-2 z-10 flex items-center gap-2">
            <h1 className="bg-gray-900 opacity-85 shadow-md uppercase text-white py-0.5 px-2 rounded-md text-sm sm:text-lg font-medium ">
              {title}
            </h1>
          </div>
          <div className="absolute bottom-2 right-2 z-10 flex items-center gap-2">
            <h1 className=" bg-gray-900 opacity-85 shadow-md text-white py-0.5 px-2 rounded-md text-sm sm:text-lg ">
              {price}
            </h1>
          </div>
          <Image
            alt={`Foto de ${title}`}
            src={replaceImageExtensionToWebP(
              replaceImageTransformation(thumbs[0], "t_medium")
            )}
            fill
            className="w-full h-full object-center lg:rounded-l-md object-cover block align-middle"
            sizes="(max-width: 640px) 100vw, 75vw"
            priority={index === 0}
            loading={index === 0 ? "eager" : "lazy"}
          />
        </div>

        {/* second div  */}
        <div className="relative md:min-h-[400px] grid gap-1 h-full grid-cols-2 sm:grid-cols-1 lg:grid-cols-2">
          {/* small thumbs */}

          {[...thumbs, thumbs[0]]
            .filter((_, idx) => idx > 0)
            .map((thumbLink, index) => {
              if (index > 3) return;
              return (
                <div
                  className={classNames(
                    "relative aspect-w-4 aspect-h-3 w-full h-full",
                    index === 2 || index === 3 ? "hidden lg:block" : ""
                  )}
                  key={index}
                >
                  <Image
                    alt={`Foto de ${title}`}
                    src={thumbLink}
                    fill
                    sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 25vw"
                    className={classNames(
                      "w-full h-full object-center object-cover",
                      index === 1 ? "lg:rounded-tr-md" : "",
                      index === 3 ? "lg:rounded-br-md" : ""
                    )}
                    priority={index === 0}
                    loading={index === 0 ? "eager" : "lazy"}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </Link>
  );
}
